import { BW_LANGUAGE_EN } from 'src/libs/qb-brand-web-components'

const brandConfig = {
  brandId: 'coincoffee',
  name: 'CoinCoffee',
  privacyPolicyUrl: 'https://www.coincoffee.life/policies/privacy-policy',
  cookiePolicyUrl:
    'https://docs.google.com/document/d/1WQSCcTgguGhhW-9sCmrSpwgOrAgkPvbBw8WPFHFc-HI/edit?usp=sharing',
  termsUrl:
    'https://docs.google.com/document/d/1Pyq9nwWseuTnA97IQCBMzdhmy2ahmrSsZ3kkMcGOxmY/edit',
  faqEmail: 'info@coincoffee.life',
  earnRewardNftUrl:
    'https://coincoffee.nftify.network/nft/genesis-autograph-collection',
  theme: {
    colors: {
      main: '#000000',
      accent: '#eac25c'
    }
  },
  supportedLanguages: [BW_LANGUAGE_EN],
  features: {},
  cognito: {
    region: 'eu-central-1',
    poolId: 'eu-central-1_sgh7CVInY',
    clientId: '140q9u4m95sb749n4ctcghcumt',
    poolDomain: 'coincoffee.auth.eu-central-1.amazoncognito.com',
    redirectUri: 'https://rewards.coincoffee.life/login'
  },
  gaTrackingId: 'G-NB4HRW28DX',
  hotjarId: '3137318'
}

export default brandConfig
