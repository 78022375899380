import React, { memo } from 'react'

import HowManyPoints from '../../../components/welcome/HowManyPoints'
import brandConfig from 'brandConfig'

const HowToEarnRewardsWrapper = ({ tokenSymbol, t }) => {
  return (
    <HowManyPoints
      data={[
        { i18nKey: 'brand:earn_rewards.label1', point: 1, tokenSymbol },
        { i18nKey: 'brand:earn_rewards.label2', point: '2X', tokenSymbol },
        {
          i18nKey: 'brand:earn_rewards.label3',
          url: t('brand:earn_rewards.nft'),
          to: brandConfig.earnRewardNftUrl,
          point: '3X',
          tokenSymbol
        },
        {
          i18nKey: 'brand:earn_rewards.label4',
          url: t('brand:earn_rewards.nft'),
          to: brandConfig.earnRewardNftUrl,
          tokenSymbol: `"${t('brand:earn_rewards.founders-bonus')}"`
        },
        {
          i18nKey: 'brand:earn_rewards.label5',
          tokenSymbol: t('brand:earn_rewards.eth-btc')
        }
      ]}
    />
  )
}

export default memo(HowToEarnRewardsWrapper)
